let enablePostProcessing = true;
export const FormattingTypes = Object.freeze({
  None: 0,
  ItalicsOn: 1,
  ItalicsOff: 2,
  BoldOn: 3,
  BoldOff: 4,
  UnderlineOn: 5,
  UnderlineOff: 6,
  SubscriptOn: 7,
  SubscriptOff: 8,
  SuperscriptOn: 9,
  SuperscriptOff: 10,
});
let formattingOptions;
let formattingChanged = false;

export function hasFormattingChanged(): boolean {
  return formattingChanged;
}

export function getFormattingType() {
  return formattingOptions;
}

export function resetFormattingType() {
  formattingOptions = FormattingTypes.None;
}

function searchWords(sentence: string, words: string[]): { [key: string]: number[] } {
  let result: { [key: string]: number[] } = {};

  words.forEach((word) => {
    let regex = new RegExp(word, "gi");
    let match: RegExpExecArray;
    let locations: number[] = [];
    while ((match = regex.exec(sentence)) !== null) {
      locations.push(match.index);
    }
    if (locations.length > 0) {
      result[word] = locations;
    }
  });

  return result;
}

function replacePunctuation(sentences: string, words: { [key: string]: string }): string {
  let newSentences: string = sentences;

  for (let key in words) {
    let regex = new RegExp(key, "gi");
    newSentences = newSentences.replace(regex, words[key]);
  }

  return newSentences;
}

export function supportCommands(content: string): string {
  let words: { [key: string]: string } = {};
  words["\\s?\\bperiod\\b"] = ".";
  words["\\s?\\bfull stop\\b"] = ".";
  words["\\s?\\bcomma\\b"] = ",";
  words["\\s?\\bquestion mark\\b"] = "?";
  words["\\s?\\bexclamation mark\\b"] = "!";
  words["\\s?\\bexclamation point\\b"] = "!";
  words["\\s?\\bnew line\\b\\s?"] = "\n";
  words["\\s?\\bapostrophe(-s|s)?"] = "'s";
  words["\\s?\\bsemi-?colon\\b"] = ";";
  words["\\s?\\bcolon\\b"] = ":";
  words["\\bopen quotes\\b\\s?"] = '"';
  words["\\s?\\bclose quotes\\b"] = '"';
  words["\\bhyphen\\b"] = "-";
  words["\\bellips(i|e)s\\b"] = "...";
  words["(dot((.|,)?\\s)?){3}"] = "...";
  words["\\b(begin|open) single quote\\b\\s?"] = "'";
  words["\\s?\\b(end|close) single quote\\b"] = "'";
  words["\\b(left|open) parenthes(e|i)s\\s?"] = "(";
  words["\\s?\\b(right|close) parenthes(e|i)s"] = ")";
  words["\\b(left|open) bracket\\s?"] = "[";
  words["\\s?\\b(right|close) bracket\\b"] = "]";
  words["\\b(left|open) brace\\s?"] = "{";
  words["\\s?\\b(right|close) brace\\b"] = "}";

  return replacePunctuation(content, words);
}

export function supportFormatting(content: string): boolean {
  // italics, italicize
  // bold
  // underline
  // subscript
  // superscript

  // Note that only formatting command can be pending (not applied)
  // at any time. For example, users cannot bold and italicize at
  // the same time. Instead, they have to "italicize" followed by
  // "some text" and then "bold" to get everything later in italics
  // and bold. Let's update logic based on customer feedback

  const maxLengthForMatches = 20;
  let foundMatch = false;
  const italicsRegEx: RegExp = /^(italicize|italics)\.?$/i;
  const italicsRemoveRegEx: RegExp = /^(remove italics|remove italicize)\.?$/i;
  const boldRegEx: RegExp = /^(bold\.?)$/i;
  const boldRemoveRegEx: RegExp = /^(remove bold\.?)$/i;
  const subscriptRegEx: RegExp = /^(sub\s?script\.?)$/i;
  const subscriptRemoveRegEx: RegExp = /^(remove sub\s?script\.?)$/i;
  const superscriptRegEx: RegExp = /^(super\s?script\.?)$/i;
  const superscriptRemoveRegEx: RegExp = /^(remove super\s?script\.?)$/i;

  if (content.length > maxLengthForMatches) {
    return foundMatch;
  }

  // At this point, assume that we're going to find a formatting
  // request (until we determine to be otherwise)
  foundMatch = true;

  if (italicsRegEx.test(content)) {
    formattingOptions = FormattingTypes.ItalicsOn;
  } else if (italicsRemoveRegEx.test(content)) {
    formattingOptions = FormattingTypes.ItalicsOff;
  } else if (boldRegEx.test(content)) {
    formattingOptions = FormattingTypes.BoldOn;
  } else if (boldRemoveRegEx.test(content)) {
    formattingOptions = FormattingTypes.BoldOff;
  } else if (subscriptRegEx.test(content)) {
    formattingOptions = FormattingTypes.SubscriptOn;
  } else if (subscriptRemoveRegEx.test(content)) {
    formattingOptions = FormattingTypes.SubscriptOff;
  } else if (superscriptRegEx.test(content)) {
    formattingOptions = FormattingTypes.SuperscriptOn;
  } else if (superscriptRemoveRegEx.test(content)) {
    formattingOptions = FormattingTypes.SuperscriptOff;
  } else {
    foundMatch = false;
  }

  return foundMatch;
}

export function doPostProcessing(content: string): string {
  // Fast path
  if (enablePostProcessing == false || content == undefined || content.length == 0) {
    return content;
  }

  // See if user provided formatting guidelines like "turn on italics"
  formattingChanged = supportFormatting(content);
  if (formattingChanged == true) {
    return undefined;
  }

  // Replace commands with equivalent text
  return supportCommands(content);
}

export function updateFormatting(selection) {
  switch (formattingOptions) {
    case FormattingTypes.None:
      break;
    case FormattingTypes.BoldOn:
      selection.font.bold = true;
      break;
    case FormattingTypes.BoldOff:
      selection.font.bold = false;
      break;
    case FormattingTypes.ItalicsOn:
      selection.font.italic = true;
      break;
    case FormattingTypes.ItalicsOff:
      selection.font.italic = false;
      break;
    case FormattingTypes.SubscriptOn:
      selection.font.subscript = true;
      break;
    case FormattingTypes.SubscriptOff:
      selection.font.subscript = false;
      break;
    case FormattingTypes.SuperscriptOn:
      selection.font.superscript = true;
      break;
    case FormattingTypes.SuperscriptOff:
      selection.font.superscript = false;
      break;
  }

  // It is sufficient to update Range (selection) once
  // as Word will keep track of the state (and we
  // don't have to)
  resetFormattingType();
  return selection;
}
